<template>
  <div id="enterpriseServiceInquiry">
    <input
        id="uploadAttanchment"
        style="display: none"
        type="file"
        @change="chooseFile($event)"
    />
    <!-- 已签约 -->
    <div class="result-panel">
      <CSTable>
        <template v-slot:thead>
          <tr>
            <th class="text-center">签约时间</th>
            <th class="text-center">项目名称</th>
            <th class="text-center">服务提供方</th>
            <th class="text-center">抽成比例</th>
            <th class="text-center">用户信息</th>
            <th class="text-center">服务日期</th>
            <th class="text-center">应付金额</th>
            <th class="text-center">已付金额</th>
            <th class="text-center">支付渠道</th>
            <th class="text-center">附件</th>
            <th class="text-center">备注</th>
            <th class="text-center">用户评价</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="!isLoading">
            <tr v-for="(log, index) in list" :key="index">
              <td class="text-center date-td">
                {{ log.signTime || "-" }}
              </td>
              <td class="text-center">
                {{ log.itemName }}
              </td>

              <template>
                <td class="text-center">
                  <p style="margin-bottom: 0">
                    {{ log.providerName || "物业" }}
                  </p>
                  <p v-if="log.providerContact" style="margin-bottom: 0">
                    {{ log.providerContact }}
                  </p>
                  <p v-if="log.providerPhone">
                    {{ log.providerPhone }}
                  </p>
                </td>
              </template>
              <td class="text-center">
                {{ log.commissionScale ? log.commissionScale + "%" : "-" }}
              </td>
              <td class="text-center">
                <p v-if="log.userName" style="margin-bottom: 0">
                  {{ log.userName }}
                  【{{ log.userPhone }}】
                </p>
                <p v-if="log.userCompany" style="margin-bottom: 0">
                  {{ log.userCompany }}
                </p>
                <p v-if="log.userCompanyAddr" style="margin-bottom: 0">
                  {{ log.userCompanyAddr }}
                </p>
              </td>
              <td class="text-center">
                <template v-if="log.serviceDateType == 1">
                  {{ log.serviceDate || "-" }}
                </template>
                <template v-else>
                  {{ log.serviceDate || "-" }}
                </template>
              </td>
              <td class="text-center">
                <template v-if="log.payableMoney">
                  {{ log.payableMoney }}
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <template v-if="log.paymentMoney">
                  {{ log.paymentMoney || "-" }}
                </template>
                <template v-else>-</template>
              </td>

              <td class="text-center">
                <template v-if="log.payChannel !== null || log.paymentOrderNo">
                  <p v-if="log.payChannel != 2" style="margin-bottom: 0">
                    {{ PAY_CHANNEL_MAPPING[log.payChannel] }}
                  </p>

                  <p
                      v-if="[0, 1, 3].includes(log.payChannel)"
                      style="margin-bottom: 0"
                  >
                    {{ log.paymentOrderNo }}
                  </p>
                  <template v-if="log.payChannel == 2 && log.voucher">
                    <span class="allow-click" @click="lookImage(log.voucher)">
                      现金凭证
                    </span>
                  </template>
                </template>
                <template v-else>-</template>
              </td>

              <td class="text-center">
                <template v-if="log.annex && JSON.parse(log.annex).length > 0">
                  <span
                      class="allow-click"
                      @click="lookContractAttachments(log.annex, log.id)"
                  >
                    查看
                  </span>
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <template v-if="log.comment">
                  <span class="allow-click" @click="lookComment(log.comment)"
                  >查看</span
                  >
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <template v-if="log.score">
                  <span
                      class="allow-click"
                      @click="lookEvaluate(log.score, log.evaluate)"
                  >
                    查看
                  </span>
                </template>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          style="width: 100%"
                          @click="
                          uploadAttachmentParams.id = log.id;
                          triggerUploadElement('#uploadAttanchment');
                        "
                      >上传附件
                      </a>
                    </li>
                    <li v-if="!log.paymentMoney">
                      <a style="width: 100%" @click="openOfflinePayment(log)"
                      >线下付款
                      </a>
                    </li>
                    <li v-if="log.paymentMoney != null">
                      <a style="width: 100%" @click="queryRefund(log)">退款 </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td :colspan="12">
              <div style="text-align: center">暂无数据</div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination></Pagination>
    </div>
    <!-- 点击备注的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="noSigning"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="备注"
        dialogWidth="480px"
        @onClose="noSigning = false"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        {{ comment }}
      </div>
    </CSDialog>
    <!-- 点击评价的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="evaluates"
        dialog-confirm-btn-text="确定"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="用户评价"
        dialogWidth="480px"
        @onClose="evaluates = false"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div>
          <span style="margin-right: 30px">星级</span><i>{{ score }}</i>
        </div>
        <div>
          <span style="margin-right: 30px">评价</span><i>{{ evaluate }}</i>
        </div>
      </div>
    </CSDialog>
    <!-- 点击退款后显示的弹出框 -->
    <CSDialog
        :dialogVisible="refundConfirmDialog.visible"
        dialog-confirm-btn-text="确定"
        dialogTitle="退款"
        dialogWidth="920px"
        @onClose="refundConfirmDialog.visible = false"
        @onConfirm="refundConfirmDialog.onOk"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div class="box_">
          <label class="hy-label">已付金额</label>
          <i>{{ refundConfirmDialog.data.paymentMoney }}元</i>
        </div>
        <div class="box_">
          <span class="hy-label">退款金额</span>
          <i>{{ refundConfirmDialog.data.paymentMoney }}元</i>
        </div>
        <div class="box_">
          <span class="hy-remarks">退款原因</span>
          <textarea
              v-model="refundConfirmDialog.refundCause"
              placeholder="请输入原因"
              style="
              background-color: #f0f0f0;
              width: 720px;
              height: 200px;
              padding-left: 10px;
              border-radius: 4px;
            "
          ></textarea>
        </div>
      </div>
    </CSDialog>
    <!-- 点击线下付款后显示的弹出框 -->
    <CSDialog
        :dialogVisible="offlinePayment"
        dialogConfirmBtnText="确定"
        dialogTitle="线下付款"
        dialogWidth="960px"
        @onClose="offlinePayment = false"
        @onConfirm="commitOfflinePay"
    >
      <div
          slot="dialog-content"
          class="box_"
          style="padding: 20px 30px; font-size: 24px"
      >
        <div class="box_">
          <label class="hy-label"> 支付渠道 </label>
          <div style="display: inline-block; vertical-align: middle">
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentParams.payChannel"
                  :value="PAY_CHANNEL.CASH_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              <i class="inputSpace" style="vertical-align: middle">现金支付</i>
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentParams.payChannel"
                  :value="PAY_CHANNEL.BANK_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              <i class="inputSpace" style="vertical-align: middle">银行转账</i>
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentParams.payChannel"
                  :value="PAY_CHANNEL.ALI_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              <i class="inputSpace" style="vertical-align: middle"
              >支付宝转账</i
              >
            </span>
            <span style="margin-right: 20px">
              <input
                  v-model="offlinePaymentParams.payChannel"
                  :value="PAY_CHANNEL.WE_CHAT_PAY"
                  style="margin-right: 10px; vertical-align: middle"
                  type="radio"
              />
              <i class="inputSpace" style="vertical-align: middle">微信转账</i>
            </span>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <label class="hy-label">应付金额</label>
          <i style="vertical-align: middle"
          >{{ offlinePaymentParams.payableMoney }} 元</i
          >
        </div>
        <div style="margin-bottom: 25px">
          <label class="hy-label">已付金额</label>
          <input
              v-model="offlinePaymentParams.paymentMoney"
              class="cs-input"
              placeholder="请输入"
              style="
              margin-left: 0;
              border-radius: 4px;
              border: 1px solid #979797;
              padding-left: 10px;
              vertical-align: middle;
            "
              type="text"
          />
          元
        </div>
        <div style="margin-bottom: 27px">
          <label class="hy-remarks">付款凭证</label>
          <div style="display: inline-block">
            <div
                v-for="(src, imgIndex) in offlinePaymentParams.voucher"
                :key="src"
                class="photo-view"
            >
              <img :src="src"/>
              <i class="icon-close" @click="deletePayVoucher(imgIndex)"></i>
            </div>
            <div
                v-if="offlinePaymentParams.voucher.length < 5"
                class="upload-photo"
                @click="triggerUploadElement('#uploadVoucherPhoto')"
            >
              <div class="text-center" style="padding-bottom: 0">
                <img
                    alt=""
                    src="../../assets/upload.png"
                    style="margin: 5px auto"
                />
                <p style="color: #999">上传照片</p>
              </div>
            </div>
            <input
                id="uploadVoucherPhoto"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="choosePhoto($event)"
            />
          </div>
        </div>
        <div>
          <label class="hy-remarks">备注</label>
          <textarea
              v-model="offlinePaymentParams.comment"
              placeholder="请输入"
              style="
              width: 720px;
              height: 200px;
              background: #f0f0f0;
              border-radius: 4px;
              padding-left: 10px;
            "
          ></textarea>
        </div>
      </div>
    </CSDialog>
    <ViewModal></ViewModal>
    <ViewImageModal componentName="ViewImageModal" name="viewImageModal">
    </ViewImageModal>
  </div>
</template>

<script>
import ViewImageModal from "@/components/ViewImageModal.vue";
import Pagination from "@/components/Pagination";
import {
  deleteAnnexUrl,
  enterpriseOfflinePaymentUrl,
  getadminQueryOrderUrl,
  refundEnterpriseUrl,
  uploadEnterpriseAnnexUrl,
} from "@/requestUrl";
import ViewModal from "@/components/ViewModal";
import {PAY_CHANNEL, PAY_CHANNEL_MAPPING} from "../../constant/index";
import CSDialog from "@/components/common/CSDialog";
import CSTable from "@/components/common/CSTable";

export default {
  components: {
    CSTable,
    ViewImageModal,
    ViewModal,
    Pagination,
    CSDialog,
  },
  name: "enterpriseServiceInquiry",
  data() {
    return {
      isLoading: false,
      PAY_CHANNEL,
      PAY_CHANNEL_MAPPING,
      uploadAttachmentParams: {
        regionId: "",
        id: 1,
        annex: "",
      },
      // 订单返回来的数据
      list: [],

      // 控制备注弹出框的显示与隐藏
      noSigning: false,
      // 控制退款弹出框的显示与隐藏
      refundConfirmDialog: {
        visible: false,
        refundCause: "",
        data: {},
        onOk: () => {
        },
      },
      // 控制线下付款弹出框的显示与隐藏
      offlinePayment: false,
      // 控制评价弹出框的显示与隐藏
      evaluates: false,
      // 备注内容
      comment: "",
      // 评分
      score: "",
      // 评价内容
      evaluate: "",
      editMeetingRoom: {
        voucher: "",
      },
      item: "",
      paymentMoney: "",

      offlinePaymentParams: {
        regionid: "",
        id: "",
        payableMoney: " ",
        payChannel: "",
        paymentMoney: "",
        voucher: [],
        comment: "",
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.isLoading = false;
  },
  mounted() {

    this.queryItem();
    this.$vc.on(
        this.$route.path,
        "pagination",
        "page_event",
        (_currentPage) => {
          this.pageParams.pageNo = _currentPage;
          this.queryItem(_currentPage);
        }
    );
  },
  methods: {
    commitOfflinePay() {
      this.$fly
          .post(enterpriseOfflinePaymentUrl, {
            id: this.offlinePaymentParams.id,
            payChannel: this.offlinePaymentParams.payChannel,
            paymentMoney: this.offlinePaymentParams.paymentMoney,
            voucher: JSON.stringify(this.offlinePaymentParams.voucher),
            comment: this.offlinePaymentParams.comment,
            regionId: this.$vc.getCurrentRegion().code,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast("提交成功");
            this.offlinePayment = false;
            this.queryItem();
          });
    },
    deletePayVoucher(index) {
      this.offlinePaymentParams.voucher.splice(index, 1);
    },
    // 查询询价中的数据
    queryItem(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(getadminQueryOrderUrl, {
            regionId: this.$vc.getCurrentRegion().communityId, //区域id
            state: 2, //1.询价中2.已签约3.不签约4.已退款
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            if (res.data.datas.length == 0) {
              this.isLoading = true;
            }

            this.list = res.data.datas;
          });
    },
    triggerUploadElement(el) {
      document.querySelector(el).click();
    },
    //  上传附件
    async uploadAttachment(annexFile) {
      const annex = await this.$vc.uploadFile(annexFile);
      console.log(annex);
      this.uploadAttachmentParams.annex = annex.data.fileSaveName;
      this.uploadAttachmentParams.regionId = this.$vc.getCurrentRegion().code;
      this.$fly
          .post(uploadEnterpriseAnnexUrl, this.uploadAttachmentParams)
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast("上传成功");
            this.queryItem();
          });
    },
    /**
     * 选择文件
     * @param {Object} event 当前元素
     *  */
    chooseFile(event) {
      return this.$vc.chooseFile(event, this.uploadAttachment);
    },
    choosePhoto(event) {
      return this.$vc.chooseFile(event, async (file) => {
        const uploadResult = await this.$vc.uploadFile(file);
        if (uploadResult.code !== 0) {
          return;
        }
        this.offlinePaymentParams.voucher.push(uploadResult.data.fileSaveName);
      });
    },
    // 拒绝签约项目事件
    noQuerySigning() {
      this.noSigning = true;
    },
    // 查看凭证
    lookImage(photos) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        photos: JSON.parse(photos),
        title: "现金凭证",
      });
    },
    deleteMeetingRoomPhotos(index) {
      this.editMeetingRoom.voucher.splice(index, 1);
    },
    // 选择文件后处理
    chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        var reader = new FileReader(); //新建FileReader对象
        reader.readAsDataURL(file); //读取为base64
        reader.onloadend = async (e) => {
          const photo = await this.uploadPhoto(reader.result).catch((err) => {
            console.error("上传照片失败");
            return "";
          });
          this.editMeetingRoom.voucher.push(photo);
        };
      }
    },
    /**
     * 查看合同附件
     * @param {Array} attachments 附件地址
     * @param {Number} id 记录id
     *  */
    lookContractAttachments(attachments, id) {
      let trStr = "";
      JSON.parse(attachments).forEach((val) => {
        trStr += `<tr>
                        <td class="text-center">${
            val.split("/")[val.split("/").length - 1]
        }</td>
                        <td class="text-center">
                            ${
            ["png", "jpg", "jpeg", "gif", "webp"].includes(
                val.split(".")[val.split(".").length - 1]
            )
                ? `<button class="btn btn-link btn-look-attachment" data-src="${val}">查看</button>`
                : ""
        }
                            <button class="btn btn-link btn-download" data-src="${val}">下载</button>
                            <button class="btn btn-link btn-delete" data-id="${id}" data-src="${val}">删除</button>
                        </td>
                    </tr>`;
      });
      this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
        title: "查看",
        content: `
                        <table class="footable table table-stripped toggle-arrow-tiny modal-inner-table">
                            <thead>
                                <tr><th class="text-center">名称</th><th class="text-center">操作</th></tr>
                            </thead>
                            <tbody>
                                ${trStr}
                            </tbody>
                        </table> `,
      });
      this.$nextTick(() => {
        const downloadBtns = document.querySelectorAll(".btn-download"),
            deleteBtns = document.querySelectorAll(".btn-delete"),
            lookBtns = document.querySelectorAll(".btn-look-attachment");
        downloadBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            this.downloadFile(e.target.dataset.src);
          });
        });
        deleteBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            const {src, id} = e.target.dataset;
            this.openDeleteFile(id, src).then((res) => {
              if (res.code === 0) {
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                el.parentNode.parentNode.parentNode.removeChild(
                    el.parentNode.parentNode
                );
                this.queryItem();
              }
            });
          });
        });
        lookBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
              photos: e.target.dataset.src,
            });
          });
        });
      });
    },
    openDeleteFile(id, annex) {
      return new Promise((resolve) => {
        this.$CSDialog.confirm({
          title: "提示",
          message: "确定删除吗？",
          onConfirm: async () => {
            resolve(await this.deleteFile(id, annex));
          },
        });
      });
    },
    deleteFile(id, annex) {
      return this.$fly.post(deleteAnnexUrl, {
        regionId: this.$vc.getCurrentRegion().code,
        annex,
        id,
      });
    },
    /**
     * 下载文件（仅限阿里oss的文件）
     * @param {String} fileSrc 文件的资源地址
     *  */
    downloadFile(fileSrc) {
      window.open(
          fileSrc.replace(
              "https://alioss.techops.cn/",
              "https://cosmos-img.oss-cn-beijing.aliyuncs.com/"
          )
      );
    },
    // 查看备注事件
    lookComment(comment) {
      this.comment = comment;
      this.noSigning = true;
    },
    // 查看评价事件
    lookEvaluate(score, evaluate) {
      this.score = score;
      this.evaluate = evaluate;
      this.evaluates = true;
    },
    // 退款事件
    queryRefund(item) {
      this.refundConfirmDialog.visible = true;
      this.refundConfirmDialog.data = item;
      this.refundConfirmDialog.refundCause = "";
      this.refundConfirmDialog.onOk = () => {
        this.$fly
            .post(refundEnterpriseUrl, {
              orderId: item.id,
              regionId: this.$vc.getCurrentRegion().code,
              refundCause: this.refundConfirmDialog.refundCause,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.refundConfirmDialog.visible = false;
              this.$vc.toast("退款成功");
              this.queryItem();
            });
      };
    },
    // 线下付款事件
    openOfflinePayment(item) {
      this.offlinePayment = true;
      this.offlinePaymentParams = {
        regionid: "",
        id: item.id,
        payableMoney: item.payableMoney,
        payChannel: "",
        paymentMoney: "",
        voucher: [],
        comment: "",
      };
    },
  },
};
</script>

<style scoped>
.el-dialog {
  border-radius: 5px !important;
}

#enterpriseServiceInquiry .el-dialog__header {
  padding: 0;
  height: 0;
}

#enterpriseServiceInquiry .el-dialog__body {
  padding: 20px 0 20px 30px;
}

#enterpriseServiceInquiry .el-dialog__footer {
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #999;
}

.signBox > el-dialog__footer {
  border-top: 1px solid #999;
}

.el-dialog .cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  padding: inherit;
  margin-right: 30px;
}

.el-dialog .confirm {
  width: 80px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  font-size: 20px;
  padding: inherit;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}

#enterpriseServiceInquiry .el-card__body {
  padding: 3px 0 0px 0;
}
</style>

<style scoped>
.text-center {
  padding-bottom: 10px;
  vertical-align: middle;
}

.box_padding {
  margin-bottom: 30px;
}

i {
  font-style: normal;
}

.yellow_ {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
}

.box_ {
  margin-bottom: 14px;
}

.top_ {
  margin-right: 30px;
  vertical-align: top !important;
}

textarea {
  border: none;
  background-color: #dee1e6;
  resize: none;
}

.top_right {
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
  text-align: right;
  width: 96px;
}

.offline-payment-dialog .box_ {
  font-size: 24px;
}

.offline-payment-dialog input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  bottom: 2px;
}

.offline-payment-dialog .inputSpace {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.hy-label {
  width: 96px;
  text-align: right;
  vertical-align: middle;
  margin: 0 40px 0 0;
}

.hy-remarks {
  width: 96px;
  margin-bottom: 0;
  text-align: right;
  vertical-align: top;
  margin-right: 40px;
  height: 24px;
  line-height: 24px;
}
</style>
